import React from 'react'

import { Wrapper, HBox } from 'src/ui/layout'
import { Header, Body } from 'src/ui/typography'
import { useTheme } from 'src/ui/theme'
import { HeroDesktopSecondary } from 'src/ui/organisms'

type Props = {}

export const AboutTraining: React.FC<Props> = () => {
  const theme = useTheme()

  return (
    <Wrapper>
      <Header size="h4">Подробнее о стажировке</Header>
      <HBox height={theme.paddings.half} />
      <Body>
        Рассказывает наш CTO и руководитель блока управления стажировками
        Николай Николенко:
      </Body>
      <HBox height={theme.paddings.main} />
      <HeroDesktopSecondary
        adornment={false}
        height={218}
        videoType="rutube"
        videoId="8f1a8ff11e0403b91e0139684e83c760"
      />
    </Wrapper>
  )
}
